
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ClientProjectsTableWrapper from '@/components/views/ClientEdit/ClientProjectsTableWrapper.vue';
import { ClientTabs } from '@/enums/components/EditClient/ClientTabs';
import CustomerInformation from '@/components/global/client/CustomerInformation.vue';
import ContactInformation from '@/components/global/client/ContactInformation.vue';
import DiscountInformation from '@/components/global/client/DiscountInformation.vue';
import Currency from '@/models/Currency';
import PaymentType from '@/models/PaymentType';
import BankAccount from '@/models/BankAccount';
import Country from '@/models/Country';
import PriceType from '@/models/PriceType';
import PriceListRegion from '@/models/PriceListRegion';
import ContactPerson from '@/models/ContactPerson';
import ContactConfig from '@/models/interfaces/Contact';
import AddressConfig from '@/models/interfaces/Address';
import Address from '@/models/Address';
import Discount from '@/models/Discount';
import Client from '@/models/Client';
import { AvailableClientFields } from '@/interfaces/components/clients/AvailableClientFields';
import ClientTypeRepository from '@/repositories/ClientTypeRepository';
import PaymentTypeRepository from '@/repositories/PaymentTypeRepository';
import ProductGroup from '@/models/ProductGroup';
import { TagTypes } from '@/interfaces/general/Tag';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { PopupEvents } from '@/enums/global/PopupEvents';
import CMSUser from '@/models/CMSUser';
import ClientType from '@/models/ClientType';
import { UserRightsEnum } from '@/enums/global/UserRights';
import { ErrorOptions } from '@/interfaces/ErrorOptions';
import { DiscountTableEntry } from '@/interfaces/components/clients/DiscountTableEntry';
import { webpageValidationRegex, emailValidationRegex } from '@/regex/formRegex';
import LeadOrigin from '@/models/LeadOrigin';

@Component({
    inject: [],
    name: 'EditClientTabs',
    components: {
        CustomerInformation,
        ContactInformation,
        DiscountInformation,
        ClientProjectsTableWrapper,
    },
})
export default class EditClientTabs extends Vue {
    @Prop({ required: true }) private clientId!: string;
    @Prop({ required: true }) private client!: Client;
    @Prop({ required: true }) private formData!: any;

    private emailValidationOverride: boolean = false;
    private emailValidationOverrideValue: boolean = false;
    private isTabLoading = false;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private deletedDiscountIds: string[] = [];
    private validationErrors: string[] = [];
    private refErrors: any[] = [];
    private isEditingDiscounts = false;
    private activeTab = 'Customer';
    private contactErrors = [];

    private mailFormat = emailValidationRegex;

    private get userRights() {
        return this.$store.getters['jwtData/userRights'];
    }

    public get canUserEditDiscounts() {
        return this.userRights.includes(UserRightsEnum.EDIT_DISCOUNTS);
    }

    private get proxyClientTabs() {
        return {
            CUSTOMER: ClientTabs.CUSTOMER,
            CONTACT: ClientTabs.CONTACT,
            PROJECTS: ClientTabs.PROJECTS,
            DISCOUNTS: ClientTabs.DISCOUNTS,
        };
    }

    private get defaultCountryId() {
        const currentlySelectedCountry = this.formData.addressForm.country;

        if (currentlySelectedCountry) {
            return currentlySelectedCountry;
        }

        return '';
    }

    private get clientTypes() {
        return ClientTypeRepository.getAll();
    }

    private get paymentTypes() {
        return PaymentTypeRepository.getAllWithArchived();
    }

    private get displayCustomerType() {
        return this.$t(this.formData.isCompany ? 'Company' : 'Natural person');
    }

    @Watch('client', {
        immediate: true,
    })
    private async onClientChange() {
        await this.$nextTick();

        const clientExists = this.client && Object.keys(this.client as { [key: string]: any }).length;
        this.isTabLoading = !clientExists;
    }

    private setFieldsBorder(arr: any[], clear: boolean = false) {
        let customerInfo = this.$refs.customerinfo as any;

        if (Array.isArray(customerInfo)) {
            customerInfo = customerInfo[0];
        }

        arr.forEach((item: any) => {
            let refElement: any;

            if (item.name === 'phone') {
                document.getElementById('phone-number-wrapper')!.style.border = clear
                    ? '1px solid #d9d9d9'
                    : '1px solid red';

                return;
            }

            if (item.type === 'regular') {
                refElement = customerInfo.$refs[item.name] as any;
            } else {
                refElement = customerInfo.$refs[item.type].$refs[item.name] as any;
            }

            if (refElement && refElement instanceof Vue) {
                refElement.$el.style.border = clear ? '1px solid #d9d9d9' : '1px solid red';
            }
        });
    }

    private confirmFieldsAreValid() {
        this.validationErrors = [];
        this.setFieldsBorder(this.refErrors, true);
        this.refErrors = [];

        let flag = true;
        let customerIsValid = true;
        let contactInfoIsValid = true;

        if (!this.formData.name) {
            flag = false;
            customerIsValid = false;
            this.validationErrors.push(this.$t('Polje ime je obavezno'));
            this.refErrors.push({ name: 'name', type: 'regular' });
        }

        if (!this.formData.addressForm.streetNameAndNumber) {
            flag = false;
            customerIsValid = false;
            this.validationErrors.push(this.$t('Polje naziv ulice i broj je obavezno'));
            this.refErrors.push({ name: 'address', type: 'address' });
        }

        if (!this.formData.addressForm.poNumber) {
            flag = false;
            customerIsValid = false;
            this.validationErrors.push(this.$t('Polje poštanski broj je obavezno'));
            this.refErrors.push({ name: 'ponumber', type: 'address' });
        }

        if (!this.formData.addressForm.city) {
            flag = false;
            customerIsValid = false;
            this.validationErrors.push(this.$t('Polje grad je obavezno'));
            this.refErrors.push({ name: 'city', type: 'address' });
        }

        if (!this.formData.addressForm.country) {
            flag = false;
            customerIsValid = false;
            this.validationErrors.push(this.$t('Polje država je obavezno'));
            this.refErrors.push({ name: 'country', type: 'address' });
        }

        // Delivery address validation
        if (this.formData.useDifferentAddressForDelivery) {
            if (!this.formData.deliveryAddressForm.streetNameAndNumber) {
                flag = false;
                customerIsValid = false;
                this.validationErrors.push(this.$t('Polje naziv ulice i broj je obavezno'));
                this.refErrors.push({ name: 'address', type: 'deliveryAddress' });
            }

            if (!this.formData.deliveryAddressForm.poNumber) {
                flag = false;
                customerIsValid = false;
                this.validationErrors.push(this.$t('Polje poštanski broj je obavezno'));
                this.refErrors.push({ name: 'ponumber', type: 'deliveryAddress' });
            }

            if (!this.formData.deliveryAddressForm.city) {
                flag = false;
                customerIsValid = false;
                this.validationErrors.push(this.$t('Polje grad je obavezno'));
                this.refErrors.push({ name: 'city', type: 'deliveryAddress' });
            }

            if (!this.formData.deliveryAddressForm.country) {
                flag = false;
                customerIsValid = false;
                this.validationErrors.push(this.$t('Polje država je obavezno'));
                this.refErrors.push({ name: 'country', type: 'deliveryAddress' });
            }
        }

        // Invoice address validation
        if (this.formData.useDifferentAddressForInvoice) {
            if (!this.formData.invoiceAddressForm.streetNameAndNumber) {
                flag = false;
                customerIsValid = false;
                this.validationErrors.push(this.$t('Polje naziv ulice i broj je obavezno'));
                this.refErrors.push({ name: 'address', type: 'invoiceAddress' });
            }

            if (!this.formData.invoiceAddressForm.poNumber) {
                flag = false;
                customerIsValid = false;
                this.validationErrors.push(this.$t('Polje poštanski broj je obavezno'));
                this.refErrors.push({ name: 'ponumber', type: 'invoiceAddress' });
            }

            if (!this.formData.invoiceAddressForm.city) {
                flag = false;
                customerIsValid = false;
                this.validationErrors.push(this.$t('Polje grad je obavezno'));
                this.refErrors.push({ name: 'city', type: 'invoiceAddress' });
            }

            if (!this.formData.invoiceAddressForm.country) {
                flag = false;
                customerIsValid = false;
                this.validationErrors.push(this.$t('Polje država je obavezno'));
                this.refErrors.push({ name: 'country', type: 'invoiceAddress' });
            }
        }

        if (this.formData.isCompany) {
            let missingContactInfo = false;
            let missingAddressInfo = false;

            const contactErrors: any = [];

            const webpageValid = this.validateWebpage();

            if (!webpageValid) {
                flag = false;
                customerIsValid = false;
                this.validationErrors.push(this.$t('Invalid webpage!'));
                this.refErrors.push({ name: 'webpage', type: 'regular' });
            }

            this.formData.contacts.forEach((contact: any, i: number) => {
                const isContactValid = !!contact.name && !!contact.email && !!contact.phone;

                const isAddressValid =
                    !!contact.address.streetNameAndNumber &&
                    !!contact.address.poNumber &&
                    !!contact.address.city &&
                    !!contact.address.country;

                if (!isContactValid) {
                    missingContactInfo = true;
                }

                if (!isAddressValid && !contact.useCompanyAddress) {
                    missingAddressInfo = true;
                }

                contactErrors.push({ index: i, errors: this.checkContactFieldsForErrors(contact) });
            });

            this.contactErrors = contactErrors;

            if (missingContactInfo) {
                flag = false;
                contactInfoIsValid = false;
                this.validationErrors.push(this.$t('Polja imena, emaila i telefona su obavezna'));
            }

            if (missingAddressInfo) {
                flag = false;
                contactInfoIsValid = false;
                this.validationErrors.push(this.$t('Polja adrese kontakt osobe su obavezna'));
            }

            // @todo check every contact
            if (!this.formData.contacts.length) {
                flag = false;
                contactInfoIsValid = false;
                this.validationErrors.push(this.$t('Nedostaje kontakt osoba'));
            }

            if (!this.formData.taxId) {
                flag = false;
                customerIsValid = false;
                this.validationErrors.push(this.$t('Polje porezni id broj je obavezno'));
                this.refErrors.push({ name: 'tax', type: 'regular' });
            }
        } else {
            if (!this.formData.phone) {
                flag = false;
                customerIsValid = false;
                this.validationErrors.push(this.$t('Polje telefon je obavezno'));
                this.refErrors.push({ name: 'phone', type: 'regular' });
            }

            if (!this.formData.email) {
                flag = false;
                customerIsValid = false;
                this.validationErrors.push(this.$t('Polje email je obavezno'));
                this.emailValidationOverride = true;
                this.emailValidationOverrideValue = false;
            } else {
                this.emailValidationOverride = false;
                this.emailValidationOverrideValue = true;
            }
        }

        this.switchToCorrectTab(customerIsValid, contactInfoIsValid);
        this.setFieldsBorder(this.refErrors);

        return flag;
    }

    private validateWebpage() {
        const input = this.formData.webpage;

        if (!input) {
            return true;
        }

        if (!webpageValidationRegex.test(input)) {
            return false;
        }

        return true;
    }

    private checkContactFieldsForErrors(contact: any) {
        const errorState: any = {};

        if (!contact.email) {
            errorState.email = true;
        }

        if (!contact.phone) {
            errorState.phone = true;
        }

        if (!contact.name) {
            errorState.name = true;
        }

        if (contact.useCompanyAddress) {
            return errorState;
        }

        if (!contact.address.city) {
            errorState.city = true;
        }

        if (!contact.address.country) {
            errorState.country = true;
        }

        if (!contact.address.poNumber) {
            errorState.poNumber = true;
        }

        if (!contact.address.streetNameAndNumber) {
            errorState.streetNameAndNumber = true;
        }

        return errorState;
    }

    private switchToCorrectTab(customerIsValid: boolean, contactInfoIsValid: boolean) {
        switch (this.activeTab) {
            case 'Customer':
                if (!customerIsValid) {
                    return;
                }

                if (!contactInfoIsValid) {
                    this.activeTab = 'Contact';
                }
                break;
            case 'Contact':
                if (!contactInfoIsValid) {
                    return;
                }

                if (!customerIsValid) {
                    this.activeTab = 'Customer';
                }
            default:
                if (!customerIsValid) {
                    this.activeTab = 'Customer';
                }

                if (!contactInfoIsValid) {
                    this.activeTab = 'Contact';
                }
        }
    }

    private openDuplicateClientPopup(duplicate: Client) {
        EventBus.$emit(EventBusEvents.openDuplicateClientPopup, {
            popupEvent: PopupEvents.openDuplicateClient,
            data: {
                client: duplicate,
            },
        });
    }

    private async confirmEmailsAreValid() {
        const customerEmail = this.formData.email;
        const contactEmails = this.formData.contacts.map((contact: any) => {
            return contact.email;
        });

        const emails = [customerEmail, ...contactEmails];
        const clients: Client[] = [];

        for await (const email of emails) {
            if (email) {
                if (!email.match(this.mailFormat)) {
                    this.$notification.error({
                        message: this.$t('Invalid email'),
                        description: '',
                    });
                    this.loadingOverlay.stop();

                    return false;
                }

                const duplicates = await this.checkDuplicateEmails(email);
                const duplicate = this.findFirstNonselfDuplicate(duplicates);
                if (duplicate) {
                    clients.push(duplicate);

                    this.openDuplicateClientPopup(duplicate);
                    this.loadingOverlay.stop();

                    return false;
                }
            }
        }

        if (clients.length === 0) {
            return Promise.resolve(true);
        }
    }

    private findFirstNonselfDuplicate(duplicates: any) {
        if (!duplicates || !duplicates.length) {
            return false;
        }

        const validDuplicate = duplicates.find((duplicate: any) => {
            return duplicate && duplicate.id !== this.client.id;
        });

        return validDuplicate;
    }

    private async checkDuplicateEmails(input: string) {
        const customerName = this.formData.name;
        const customerAddress = this.formData.addressForm.streetNameAndNumber;
        const customerTaxId = this.formData.taxId;
        let clients = null;

        try {
            if (this.formData.isCompany && Array.isArray(this.formData.contacts)) {
                for (const contact of this.formData.contacts) {
                    const result = await Client.validateClient(
                        contact.email,
                        contact.phone,
                        customerName,
                        customerAddress,
                        customerTaxId
                    );
                    if (result && result.data.length > 0) {
                        return result.data;
                    }
                }
            } else {
                clients = await Client.validateClient(
                    input,
                    this.formData.phone,
                    customerName,
                    customerAddress,
                    customerTaxId
                );
                return clients.data.length <= 0 ? null : clients.data;
            }
        } catch (e) {
            console.error('Error checking duplicates', e);
            return null;
        }

        return null;
    }

    private isCustomerInfoShown(tab: string) {
        return tab === ClientTabs.CUSTOMER;
    }
    private isContactInfoShown(tab: string) {
        return tab === ClientTabs.CONTACT;
    }
    private isProjectsShown(tab: string) {
        return tab === ClientTabs.PROJECTS;
    }
    private isDiscountsShown(tab: string) {
        return tab === ClientTabs.DISCOUNTS;
    }
    private updateIsEditingDiscounts(val: boolean) {
        this.isEditingDiscounts = val;
    }
    private async updateCachedValues() {
        EventBus.$emit(EventBusEvents.updateClientCachedData);
        await this.$nextTick();
    }
    private async updateClientAndDepenencies() {
        if (this.canUserEditDiscounts && this.isEditingDiscounts) {
            this.$notification.error({
                message: this.$t('Greška') as string,
                description: this.$t(
                    'You are still editing some discounts, please accept or decline changes to save.'
                ) as string,
            });
            return;
        }
        this.loadingOverlay.start();

        const data = this.formData as AvailableClientFields;
        this.formatDataForUpdate(data);

        if (!this.confirmFieldsAreValid()) {
            this.$notification.error({
                message: this.validationErrors.join('!\n'),
                description: '',
            });

            this.loadingOverlay.stop();

            return;
        }

        const emailsAreValid = await this.confirmEmailsAreValid();

        if (!emailsAreValid) {
            this.loadingOverlay.stop();

            return;
        }

        await this.updateCachedValues();

        try {
            if (this.canUserEditDiscounts) {
                await this.createDiscounts();

                EventBus.$emit('discountsUpdated');
            }

            data.contactPersons = await this.updateContacts();
            await this.updateClient(data);
        } catch (e) {
            const error = e as ErrorOptions;

            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: error.response ? error.response.data.meta.message : error.message,
            });
            this.loadingOverlay.stop();
            return;
        }

        this.$notification.success({
            message: this.$t('Client has been updated!') as string,
            description: '',
            duration: 300,
            class: 'client-update-success',
        });

        this.$emit('clientUpdated');
        this.loadingOverlay.stop();
    }

    private async updateClientWithDuplicatedValues() {
        const data = this.formData as AvailableClientFields;
        this.formatDataForUpdate(data);

        try {
            if (this.canUserEditDiscounts) {
                await this.createDiscounts();

                EventBus.$emit('discountsUpdated');
            }
            data.contactPersons = await this.updateContacts();
            await this.updateClient(data);
        } catch (e) {
            const error = e as ErrorOptions;

            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: error.response ? error.response.data.meta.message : error.message,
            });
            this.loadingOverlay.stop();
            return;
        }

        this.$notification.success({
            message: this.$t('Client has been updated!') as string,
            description: '',
            duration: 3,
            class: 'client-update-success',
        });

        this.$emit('clientUpdated');
        this.loadingOverlay.stop();
    }

    private formatDataForUpdate(data: AvailableClientFields) {
        if (data.addressForm && !data.addressForm.country) {
            data.addressForm.country = this.defaultCountryId;
        }

        if (data.assignedUser && typeof data.assignedUser !== 'string' && data.assignedUser?.id) {
            data.assignedUser = data.assignedUser.id;
        }

        if (typeof data.tags[0] !== 'string') {
            data.tags = data.tags.map((tag: any) => tag.name);
        }

        if (!data.isCompany && data.contacts.length) {
            data.contacts[0].name = data.name;
            data.contacts[0].email = data.email;
            data.contacts[0].phone = data.phone;

            data.contacts[0].address = {
                streetNameAndNumber: data.addressForm.streetNameAndNumber,
                poNumber: data.addressForm.poNumber,
                city: data.addressForm.city,
                building: data.addressForm.building,
                country: data.addressForm.country,
            };
        }

        if (!data.isCompany && !data.contacts.length) {
            data.contacts = [
                {
                    address: {
                        streetNameAndNumber: data.addressForm.streetNameAndNumber,
                        poNumber: data.addressForm.poNumber,
                        city: data.addressForm.city,
                        building: data.addressForm.building,
                        country: data.addressForm.country,
                    },
                    name: data.name,
                    email: data.email,
                    phone: data.phone,
                    job: undefined,
                    useCompanyAddress: true,
                },
            ];
        }

        if (data.discounts.length) {
            for (const discount of data.discounts) {
                if (discount.pc1) {
                    discount.pc1 = parseFloat(discount.pc1);
                } else {
                    discount.pc1 = 0;
                }

                if (discount.pc2) {
                    discount.pc2 = parseFloat(discount.pc2);
                } else {
                    discount.pc2 = 0;
                }
            }
        }
    }

    private async updateClient(data: AvailableClientFields) {
        if (data.tags.length > 0) {
            data.clientTypes = data.tags;
        } else {
            data.clientTypes = this.findClientTypeId(data);
        }
        data.paymentType = this.findPaymentTypeId(data);

        try {
            await this.handleAddressAPI(data.addressForm.id, data.addressForm, 'address', true);
            await Promise.all([
                this.handleAddressAPI(
                    data.deliveryAddressForm.id,
                    data.deliveryAddressForm,
                    'deliveryAddress',
                    data.useDifferentAddressForDelivery
                ),
                this.handleAddressAPI(
                    data.invoiceAddressForm.id,
                    data.invoiceAddressForm,
                    'invoiceAddress',
                    data.useDifferentAddressForInvoice
                ),
            ]);
        } catch (e) {
            const error = e as ErrorOptions;
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: error.response ? error.response.data.meta.message : error.message,
            });

            this.loadingOverlay.stop();
            return;
        }

        try {
            this.handleClientCompanyChange();
            await Client.updateExisting(this.clientId, data);
            EventBus.$emit(EventBusEvents.initializeClientAttachments);
        } catch (e) {
            const error = e as ErrorOptions;
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: error.response ? error.response.data.meta.message : error.message,
            });

            this.loadingOverlay.stop();
            return;
        }

        return Promise.resolve();
    }

    private async handleAddressAPI(
        addressId: string,
        addressForm: AddressConfig,
        idFieldForUpdate: string,
        shouldRun: boolean
    ) {
        if (!shouldRun) {
            this.formData[idFieldForUpdate] = this.formData.addressForm.id;
            return Promise.resolve();
        }

        let addressPromise;

        try {
            if (addressId) {
                addressPromise = await Address.updateExisting(addressId, addressForm);
                this.formData[idFieldForUpdate] = addressPromise.data.id;
            } else {
                addressPromise = await Address.createNew(addressForm);
                this.formData[idFieldForUpdate] = addressPromise.data.id;
            }
        } catch (e) {
            this.loadingOverlay.stop();
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    private async updateContacts() {
        const contacts = [];

        try {
            for (const contact of this.formData.contacts as ContactConfig[]) {
                // Ignore empty contact form when client is person type
                if (!this.formData.isCompany && (!contact.name || !contact.email || !contact.phone)) {
                    continue;
                }

                if (contact.useCompanyAddress) {
                    contact.address_id = this.formData.addressForm.id;
                }

                if (contact.id) {
                    // Fix formatting for countries if needed
                    this.fixContactPersonCountryFormatting(contact);

                    if (!contact.useCompanyAddress && contact?.address?.id) {
                        await Address.updateExisting(contact.address_id!, contact.address!);
                    }

                    if (!contact.useCompanyAddress && !contact?.address?.id) {
                        const newAddress = await Address.createNew(contact.address!);
                        contact.address_id = newAddress.data.id;
                    }

                    const existingContact = await ContactPerson.updateExisting(contact.id!, contact);

                    contacts.push(existingContact.data.id);
                } else {
                    if (!contact.useCompanyAddress) {
                        const newAddress = await Address.createNew(contact.address!);
                        contact.address_id = newAddress.data.id;
                    }

                    const newContact = await ContactPerson.createNew(contact);

                    contacts.push(newContact.data.id);
                }
            }
        } catch (e) {
            const error = e as ErrorOptions;
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: error.response ? error.response.data.meta.message : error.message,
            });

            this.loadingOverlay.stop();
            return;
        }

        return contacts;
    }

    private fixContactPersonCountryFormatting(contact: ContactConfig) {
        if (contact.address && contact.address.country) {
            if (typeof contact.address.country === 'string') {
                contact.address.country = contact.address.country.split('|')[0];
            } else if (contact.address.country.id) {
                contact.address.country = contact.address.country.id;
            }
        }
    }

    private async createDiscounts() {
        await this.deleteDiscounts();

        const promises: Array<Promise<any>> = [];
        this.formData.discounts.forEach((discount: DiscountTableEntry) => {
            if (discount.id) {
                promises.push(Discount.updateExisting(discount.id, discount));
            }
        });

        try {
            for (const discount of this.formData.discounts as DiscountTableEntry[]) {
                if (discount.id) {
                    continue;
                }

                // Create new discounts
                discount.clientId = this.clientId;
                const resp = await Discount.createNew(discount);
                discount.id = resp.data.id;
                discount.isEditing = false;
            }

            await Promise.allSettled(promises);
        } catch (e) {
            const error = e as ErrorOptions;
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: error.response ? error.response.data.meta.message : error.message,
            });
            this.loadingOverlay.stop();
            return;
        }

        return Promise.resolve();
    }

    private async deleteDiscounts() {
        const promises: Array<Promise<any>> = [];

        this.deletedDiscountIds.forEach(async (id: string, i: number) => {
            promises.push(Discount.deleteExisting(id));
        });

        try {
            await Promise.allSettled(promises);
        } catch (e) {
            const error = e as ErrorOptions;
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: error.response ? error.response.data.meta.message : error.message,
            });
            this.loadingOverlay.stop();
            return;
        }

        this.deleteDiscountsLocally();
        return Promise.resolve();
    }

    private deleteDiscountsLocally() {
        this.formData.discounts = this.formData.discounts.filter(
            (discount: DiscountTableEntry) => !this.deletedDiscountIds.includes(discount.id)
        );

        this.deletedDiscountIds = [];
    }

    private handleClientCompanyChange() {
        this.client?.setTag({
            name: TagTypes.CUSTOMER_TYPE,
            value: this.displayCustomerType,
        });
    }

    private hasAddressData(address?: AddressConfig) {
        return !!address?.streetNameAndNumber || !!address?.poNumber || !!address?.city || !!address?.country;
    }

    private findClientTypeId(data: AvailableClientFields) {
        const code = data.isCompany ? 'FIRM' : 'CUSTOMER';
        const typeId = this.clientTypes.find((type: any) => type.code === code)?.id;
        return [typeId];
    }

    private findPaymentTypeId(data: AvailableClientFields) {
        if (!!data.paymentType) {
            return data.paymentType;
        }
        return this.paymentTypes.find((type: any) => type.isDefault)?.id;
    }

    private updateDeletedDiscountIds(id: string) {
        this.deletedDiscountIds.push(id);
    }

    private async fetchInitialClientData() {
        try {
            await Promise.allSettled([
                Currency.getAll(),
                PaymentType.getAll(),
                ClientType.getAll(),
                BankAccount.getAll(),
                Country.getAll(),
                PriceType.getAll(),
                PriceListRegion.getAll(),
                ProductGroup.getAll(),
                CMSUser.getAllOnlyUsers(),
                LeadOrigin.getAll(),
            ]);
        } catch (e) {
            Promise.reject(e);
        }
    }

    private logActiveTab(activeTab: any) {
        this.activeTab = activeTab;
    }

    private async created() {
        await this.fetchInitialClientData();
        EventBus.$on(EventBusEvents.updateClient, this.updateClientAndDepenencies);
        EventBus.$on(EventBusEvents.saveClientWithDuplicates, this.updateClientWithDuplicatedValues);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.updateClient, this.updateClientAndDepenencies);
        EventBus.$off(EventBusEvents.saveClientWithDuplicates, this.updateClientWithDuplicatedValues);
    }
}
