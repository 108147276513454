
import { Vue, Component, Watch } from 'vue-property-decorator';
import ClientTopBar from '@/components/views/NewClientView/ClientTopBar.vue';
import EditClientTabs from '@/components/views/NewClientEdit/EditClientTabs.vue';
import Client from '@/models/Client';
import ClientViewSidebar from '@/components/global/sidebar/ClientViewSidebar.vue';
import ClientRepository from '@/repositories/ClientRepository';
import { NewFormData } from '@/interfaces/components/clients/NewFormData';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { ClientAdditionalInformation } from '@/interfaces/components/clients/ClientAdditionalInformation';
import ContactConfig from '@/models/interfaces/Contact';

@Component({
    name: 'NewEditClientView',
    components: { EditClientTabs, ClientViewSidebar, ClientTopBar },
})
export default class NewEditClientView extends Vue {
    private clientId: null | string = null;
    private client: any | null = null;

    private formData: NewFormData = { ...this.emptyFormData() };
    private initialLoading = true;
    private emptyFormData() {
        return {
            initialized: false,
            isCompany: false,
            email: '',
            phone: '',
            name: null,
            addressForm: {
                id: null,
                streetNameAndNumber: null,
                poNumber: null,
                city: null,
                building: null,
                country: null,
            },
            address: null,
            deliveryAddressForm: {
                id: null,
                streetNameAndNumber: null,
                poNumber: null,
                city: null,
                building: null,
                country: null,
            },
            deliveryAddress: null,
            invoiceAddressForm: {
                id: null,
                streetNameAndNumber: null,
                poNumber: null,
                city: null,
                building: null,
                country: null,
            },
            invoiceAddress: null,
            useDifferentAddressForDelivery: false,
            useDifferentAddressForInvoice: false,
            taxId: null,
            registerId: null,
            isTaxPayer: null,
            bankCode: null,
            currency: null,
            paymentType: null,
            priceType: null,
            priceListRegion: null,
            assignedUser: null,
            tags: [''],
            additionalNotes: '',
            contacts: [],
            discounts: [],
            contactPersons: [''],
            discount_ids: [''],
            clientTypes: [],
            sidebarData: {
                relatedFiles: [],
                customerHistory: null,
            },
            webpage: '',
            bigLogo: null,
            squareLogo: null,
            roltekAppPartner: false,
            backofficeUser: null,
            leadOrigin: null,
        };
    }

    private setDefaultContactAsFirst() {
        const contacts = this.client.contactPersons.slice();

        const defaultContactIndex = contacts.findIndex((contact: any) => contact.isDefault);

        if (defaultContactIndex < 0) {
            contacts[0].isDefault = true;
        } else {
            contacts.unshift(contacts.splice(defaultContactIndex, 1)[0]);
        }

        this.formData.contacts = contacts;
    }

    private setUseCompanyButtonForContacts() {
        // if contact has same address id as client address id, set use company address to true
        this.formData.contacts.forEach((contact: ContactConfig) => {
            if (contact.address && contact.address.id === this.client.address.id) {
                this.$set(contact, 'useCompanyAddress', true);
            }
        });
    }

    private serializeDataOnLoad() {
        this.formData = { ...this.emptyFormData() };

        if (this.client === null) {
            return;
        }

        this.setDefaultContactAsFirst();
        this.setUseCompanyButtonForContacts();

        if (!this.client.isCompany) {
            this.formData.isCompany = false;

            this.formData.email = this.client.contactPersons[0].email;
            this.formData.phone = this.client.contactPersons[0].phone;
        } else {
            this.formData.isCompany = true;

            this.formData.taxId = this.client.taxId;
            this.formData.registerId = this.client.registerId;
            this.formData.isTaxPayer = this.client.isTaxPayer;
            this.formData.bankCode = this.client.bankCode;
            this.formData.currency = this.client.currency_id;
            this.formData.paymentType = this.client.paymentType_id;
        }

        if (this.client.address && this.client.address.phone && !this.client.contactPersons[0].phone) {
            this.formData.phone = this.client.address.phone;
        }

        this.formData.name = this.client.name;
        if (this.client?.address) {
            this.formData.addressForm = {
                id: this.client.address.id,
                streetNameAndNumber: this.client.address.streetNameAndNumber,
                poNumber: this.client.address.poNumber,
                city: this.client.address.city,
                building: this.client.address.building,
                country: this.client.address.country.id,
            };
        }
        if (this.client?.deliveryAddress) {
            if (this.client.deliveryAddress.id !== this.client.address.id) {
                this.formData.useDifferentAddressForDelivery = true;
            }

            this.formData.deliveryAddressForm = {
                id: this.client.deliveryAddress.id,
                streetNameAndNumber: this.client.deliveryAddress.streetNameAndNumber,
                poNumber: this.client.deliveryAddress.poNumber,
                city: this.client.deliveryAddress.city,
                building: this.client.deliveryAddress.building,
                country: this.client.deliveryAddress.country.id ?? this.client.address.country.id,
            };
        }
        if (this.client?.invoiceAddress) {
            if (this.client.invoiceAddress.id !== this.client.address.id) {
                this.formData.useDifferentAddressForInvoice = true;
            }

            this.formData.invoiceAddressForm = {
                id: this.client.invoiceAddress.id,
                streetNameAndNumber: this.client.invoiceAddress.streetNameAndNumber,
                poNumber: this.client.invoiceAddress.poNumber,
                city: this.client.invoiceAddress.city,
                building: this.client.invoiceAddress.building,
                country: this.client.invoiceAddress.country.id ?? this.client.address.country.id,
            };
        }

        this.formData.address = null;
        this.formData.priceType = this.client.priceType_id;
        this.formData.priceListRegion = this.client.priceListRegion_id;
        if (this.client.discounts.length > 0) {
            this.formData.discounts = this.client.discounts.map((discount: any) => {
                return {
                    id: discount.id,
                    name: discount.discountedEntity.name,
                    discountCategory: discount.discountType.toUpperCase(),
                    discountedEntityOid: discount.discountedEntityOid ? discount.discountedEntityOid : discount.oid,
                    productGroup: discount.productGroup_id,
                    isEditing: false,
                    isPercentage: discount.isPercentage,
                    pc1: discount.price1[0],
                    pc2: discount.price2[0],
                };
            });
        }

        this.formData.assignedUser = this.client?.assignedUser?.id ?? '';
        this.formData.additionalNotes = this.client?.additionalNotes ?? '';
        this.formData.tags = this.client.clientType_ids.slice();
        this.formData.webpage = this.client?.webpage ?? '';
        this.formData.bigLogo = this.client?.bigLogo;
        this.formData.squareLogo = this.client?.squareLogo;
        this.formData.roltekAppPartner = this.client?.roltekAppPartner;
        this.formData.backofficeUser = this.client?.backofficeUser?.id ?? '';
        this.formData.leadOrigin = this.client?.origin?.id ?? '';
        this.formData.initialized = true;
    }

    private async fetchClient() {
        this.client = null;
        this.clientId = this.$route.path.includes('client/new') ? null : this.$route.params.clientId;

        if (this.clientId !== null) {
            await Client.getById(this.clientId);
        }

        this.client = ClientRepository.getById(this.clientId);
        this.serializeDataOnLoad();

        return Promise.resolve();
    }

    private get isClientExisting() {
        return this.client !== null;
    }

    private get isSaveButtonDisabled() {
        return false;
    }

    private updateClientData(data: ClientAdditionalInformation) {
        // loop through values and update the form data with the new values if there are fields to update
        for (const key in data.values) {
            if (data.fields.includes(key)) {
                switch (key) {
                    case 'city':
                        this.formData.addressForm!.city = data.values[key];
                        break;
                    case 'country':
                        this.formData.addressForm!.country = String(data.values[key]);
                        break;
                    case 'fullName':
                    case 'shortName':
                        this.formData.name = data.values[key];
                        break;
                    case 'postalCode':
                        this.formData.addressForm!.poNumber = data.values[key];
                        break;
                    case 'street':
                        this.formData.addressForm!.streetNameAndNumber = data.values[key];
                        break;
                    case 'registerID':
                        this.formData.registerId = data.values[key];
                        break;
                    case 'taxNumber':
                        this.formData.taxId = data.values[key];
                        break;
                    case 'taxPayer':
                        this.formData.isTaxPayer = data.values[key] ? true : false;
                        break;
                }
            }
        }
    }

    private created() {
        EventBus.$on(EventBusEvents.updateClientData, this.updateClientData);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.updateClientData, this.updateClientData);
    }

    private async mounted() {
        await this.fetchClient();

        this.initialLoading = false;
    }

    @Watch('$route')
    private async onRouteChange() {
        await this.fetchClient();
    }

    @Watch('formData.email')
    private onEmailChange(newVal: string, oldVal: string) {
        if (!this.formData.initialized) {
            return;
        }

        if (this.formData.contacts.length) {
            this.formData.contacts[0].email = newVal;
        }
    }

    @Watch('formData.isCompany', {
        immediate: false,
    })
    private onUserTypeChange(newVal: boolean, oldVal: boolean) {
        if (!this.formData.initialized) {
            return;
        }

        if (!newVal) {
            this.formData.webpage = null;
        }

        if (!newVal && oldVal && this.formData.contacts.length) {
            this.formData.phone = this.formData.contacts[0].phone as string;
            this.formData.email = this.formData.contacts[0].email as string;
        } else if (newVal && !oldVal && this.formData.contacts.length < 2) {
            if (!this.formData.contacts[0].email) {
                this.formData.contacts[0].email = this.formData.email;
            }

            if (!this.formData.contacts[0].phone) {
                this.formData.contacts[0].phone = this.formData.phone;
            }
        }
    }

    @Watch('formData.contacts.length')
    private onFirstContactCreate(newVal: number) {
        if (newVal !== 1) {
            return;
        }

        if (this.formData.isCompany && this.formData.email) {
            this.formData.contacts[0].email = this.formData.email;
        }
    }
}
