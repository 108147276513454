
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SidebarCard from '@/components/global/sidebar/SidebarCard.vue';
import ChatMessageList from '@/components/views/ChatView/ChatMessageList.vue';
@Component({
    name: 'ClientViewSidebarGeneralInfo',
    components: {
        SidebarCard,
        ChatMessageList,
    },
})
export default class ClientViewSidebarGeneralInfo extends Vue {
    @Prop({ required: true }) private data!: {
        id: string;
        ingeniousId: string | null;
        isRoltekPartner: boolean;
        isRoltek: boolean;
    };

    private localCustomerId: string | null = this.data.id;
    private localIngeniousCustomerId: string | null = this.data.ingeniousId;
}
