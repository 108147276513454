
import { Component, Prop, Vue } from 'vue-property-decorator';
import SidebarCard from '@/components/global/sidebar/SidebarCard.vue';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';

@Component({
    name: 'ClientViewSidebarOfficeUser',
    components: {
        SidebarCard,
    },
})
export default class ClientViewSidebarOfficeUser extends Vue {
    @Prop({ required: true }) private value!: string;

    private editModeOn = false;
    private backofficeUser: string = '';
    private cachedUser: string = '';

    private get userName() {
        if (this.value && !this.backofficeUser) {
            this.backofficeUser = this.value;
        }

        return CMSUserRepository.getSalesmanById(this.value)?.name ?? '';
    }

    private get salesmen() {
        return CMSUserRepository.getAllSalesmen();
    }

    private onEdit() {
        this.cachedUser = this.backofficeUser;
        this.editModeOn = true;
    }

    private onCancel() {
        this.backofficeUser = this.cachedUser;
        this.editModeOn = false;
    }

    private handleChange(id: string) {
        this.backofficeUser = id;
    }

    private setLocalData(): void {
        this.editModeOn = false;

        this.$emit('input', this.backofficeUser);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.updateClientCachedData, this.setLocalData);
    }

    private mounted() {
        EventBus.$on(EventBusEvents.updateClientCachedData, this.setLocalData);
    }
}
