
import { Component, Prop, Vue } from 'vue-property-decorator';
import SidebarCard from '@/components/global/sidebar/SidebarCard.vue';
import ProjectHistoryModal from '@/components/global/ProjectHistoryModal.vue';
import { ClientLog } from '@/interfaces/components/clients/ClientLog';

@Component({
    name: 'ClientViewSidebarHistory',
    components: {
        SidebarCard,
        ProjectHistoryModal,
    },
})
export default class ClientViewSidebarHistory extends Vue {
    @Prop({ required: false }) private clientLogs!: ClientLog[];

    private isClientHistoryModalVisible = false;
}
