
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { RouteNames } from '@/enums/routes/RouteNames';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import Project from '@/models/Project';
import ProjectRepository from '@/repositories/ProjectRepository';
import Client from '@/models/Client';
import { ProductCategories } from '@/enums/global/ProductCategories';
import { AxiosResponse } from 'axios';
import { AvailableClientFields } from '@/interfaces/components/clients/AvailableClientFields';
import { UserRightsMixin } from '@/mixins/UserRights';
import { mixins } from 'vue-class-component';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { PopupEventData } from '@/interfaces/components/PopupEventData';
import { PopupEvents } from '@/enums/global/PopupEvents';
import HelpPage from '@/components/global/HelpPage.vue';
import { ErrorOptions } from '@/interfaces/ErrorOptions';

@Component({
    name: 'ClientTopBar',
    components: {
        HelpPage,
    },
})
export default class ClientTopBar extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({ required: true }) private client!: Client;
    @Prop({ required: true }) private clientId!: string | null;
    @Prop({ required: false }) private projectId!: string | null;
    // @Prop({ required: true }) private hasUnsavedData!: boolean;
    @Prop({ required: true }) private isClientExisting!: boolean;
    @Prop({ required: true }) private isSaveButtonDisabled!: boolean;
    // @Prop({ required: true }) private duplicateValueDetected!: boolean;
    // @Prop({ required: true }) private formWithContactPersons!: Vue;
    // @Prop({ required: true }) private formWithAddress!: Vue;
    // @Prop({ required: true }) private clientInfo!: AvailableClientFields;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private get productType(): string {
        return this.$route.path.includes('client') ? ProductCategories.customers : ProductCategories.projects;
    }
    private get productName(): string | null {
        return this.$route.path.includes('client') ? this.client.name : '';
    }
    private get returnRoute(): string {
        return this.$route.path.includes('client') ? RouteNames.client : RouteNames.project;
    }
    private goBack(): void {
        this.$router.push({ name: this.returnRoute });
    }

    private onSaveClient() {
        EventBus.$emit(EventBusEvents.updateClient);
    }

    private async sendToIngenious() {
        if (this.clientId == null) {
            return;
        }

        try {
            EventBus.$emit(EventBusEvents.updateClient);
            await Client.sendToIngenious(this.clientId);
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });

            return;
        }

        this.$notification.success({
            message: this.$t('Klijent je uspješno poslan u Ingenious!') as string,
            description: '',
        });
    }

    private async onDeleteClient() {
        const popupData: PopupEventData = {
            popupEvent: PopupEvents.deleteClientPerson,
            data: {
                clientId: this.clientId,
                client: this.client,
                deleteClient: this.deleteClientAndClosePopup.bind(this),
            },
        };

        EventBus.$emit(EventBusEvents.onDeleteClientPerson, popupData);
    }

    private async deleteClientAndClosePopup(params: any) {
        const clientId = params.clientId as string;
        const projects = params.projects as Project[];
        const newClientId = params.newClientId ? (params.newClientId as string) : null;
        const newContactPersonId = params.newContactPersonId ? (params.newContactPersonId as string) : null;

        this.loadingOverlay.start();
        try {
            if (projects.length && newClientId && newContactPersonId) {
                // tslint:disable
                const updateCalls: Promise<AxiosResponse<any>>[] = [];

                projects.forEach((project: Project) => {
                    updateCalls.push(
                        Project.updateClientAndContactPerson({
                            projectId: project.id,
                            clientId: newClientId,
                            contactPersonId: newContactPersonId,
                        })
                    );
                });

                await Promise.all(updateCalls);
            }

            await this.deleteClient(clientId);
        } catch (e) {
            const error = e as ErrorOptions;

            this.$notification.error({
                message: this.$t('There was an error') as string,
                description: error.response ? error.response.data.meta.message : error.message,
            });
            this.loadingOverlay.stop();
            return;
        }
        this.$notification.success({
            message: this.$t('Client successfully deleted!') as string,
            description: '',
            class: 'client-delete-success',
        });

        EventBus.$emit(EventBusEvents.closePopup);

        this.$router.push({ name: RouteNames.client });
        this.loadingOverlay.stop();

        return Promise.resolve();
    }

    private async deleteClient(clientId: string) {
        try {
            await Client.deleteExisting(clientId);
        } catch (error) {
            return Promise.reject(error);
        }
        return Promise.resolve();
    }

    private getClientInformation() {
        EventBus.$emit(EventBusEvents.getClientInformation);
    }
}
