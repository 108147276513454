
import ProjectLog from '@/models/ProjectLog';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'ProjectHistoryModal',
})
export default class ProjectHistoryModal extends Vue {
    @Prop({ required: true }) private historyItems!: ProjectLog[];
    @Prop({ default: false }) private isProjectHistoryModalVisible!: boolean;
    @Prop({ default: 'Project history' }) private title!: string;

    private get items() {
        if (!this.historyItems) {
            return [];
        }
        return this.historyItems.sort(
            (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        );
    }

    private closeProjectHistoryPopup() {
        this.$emit('update:isProjectHistoryModalVisible', false);
    }
}
