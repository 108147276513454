
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SidebarCard from '@/components/global/sidebar/SidebarCard.vue';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';

@Component({
    name: 'ClientViewSidebarCustomerNotes',
    components: {
        SidebarCard,
    },
})
export default class ClientViewSidebarCustomerNotes extends Vue {
    @Prop({ required: true }) private value!: string;

    private editModeOn = false;
    private text: string = '';
    private cachedText: string = '';

    private onEdit(): void {
        this.editModeOn = true;
        this.cachedText = this.text;
    }

    private onCancel() {
        this.text = this.cachedText;
        this.editModeOn = false;
    }

    private setLocalData() {
        this.editModeOn = false;
        this.$emit('input', this.text);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.updateClientCachedData, this.setLocalData);
    }

    private mounted() {
        this.text = this.value;
        EventBus.$on(EventBusEvents.updateClientCachedData, this.setLocalData);
    }
}
