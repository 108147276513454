
import { Component, Prop, Vue } from 'vue-property-decorator';
import SidebarCard from '@/components/global/sidebar/SidebarCard.vue';
// @ts-ignore
import downloadFile from 'downloadjs';
import { Upload } from 'ant-design-vue';
import ClientAttachmentRepository from '@/repositories/ClientAttachmentRepository';
import ClientAttachment from '@/models/ClientAttachment';
import { CustomRequestParameters } from '@/interfaces/general/CustomRequestParameters';
import { customHandlerForFileUpload } from '@/helpers/ClientAttachment/ClientAttachmentHelper';
import Attachment from '@/models/Attachment';

@Component({
    name: 'ClientViewSidebarAttachments',
    components: {
        UploadDragger: Upload.Dragger,
        SidebarCard,
    },
})
export default class ClientViewSidebarAttachments extends Vue {
    @Prop({ required: true }) private client!: any;
    private loading: string | null = null;
    private showDropzone = false;
    private attachments: ClientAttachment[] = [];

    // hidding of the dropzone is delayed, to prevent flickering effect when draging over different child components
    private toggleDropzineTimer: NodeJS.Timeout | undefined;

    public async created() {
        await this.initializeClientAttachment();
    }

    private toggleDropzone(show: boolean) {
        if (show) {
            this.showDropzone = true;
            if (this.toggleDropzineTimer) {
                clearTimeout(this.toggleDropzineTimer);
            }
        } else {
            this.toggleDropzineTimer = setTimeout(() => {
                this.showDropzone = false;
            }, 500);
        }
    }

    private get logoIds() {
        const logoArr = [];

        if (this.client.bigLogo) {
            logoArr.push(this.client.bigLogo);
        }

        if (this.client.squareLogo) {
            logoArr.push(this.client.squareLogo);
        }

        return logoArr;
    }

    private async initializeClientAttachment() {
        const response = await ClientAttachment.getClientAttachmentByClientId(this.client.clientId);
        this.attachments = response.data.filter((attachment: Attachment) => !this.logoIds.includes(attachment.id));
    }

    private async manualUpload(file: File) {
        await this.customRequest({
            file,
            onSuccess: (formData: FormData) => {
                return;
            },
            onError: () => {
                return;
            },
        });
        return false;
    }

    private async customRequest(customRequestParameters: CustomRequestParameters) {
        await customHandlerForFileUpload(customRequestParameters, String(this.client.clientId));
        await this.initializeClientAttachment();
    }

    private startLoading(clientAttachmentId: string) {
        this.loading = clientAttachmentId;
    }

    private endLoading() {
        this.loading = null;
    }

    private onOpenRemoveModal(clientAttachmentForDelete: ClientAttachment) {
        return new Promise((resolve) => {
            this.$confirm({
                title: this.$t('Jeste li sigurni da želite primjeniti ove promjene?') as string,
                content: '',
                okText: this.$t('Da') as string,
                okType: 'danger',
                cancelText: this.$t('Ne') as string,
                class: 'c-delete-modal',
                onOk: () => {
                    this.onRemove(clientAttachmentForDelete);
                    resolve(null);
                },
                onCancel: () => {
                    resolve(null);
                },
            });
        });
    }
    private async onRemove(clientAttachmentForDelete: ClientAttachment) {
        this.startLoading(clientAttachmentForDelete.id);
        await ClientAttachment.deleteExisting(clientAttachmentForDelete.id);
        await this.initializeClientAttachment();
        this.endLoading();
    }

    private async onPreview(clientAttachment: ClientAttachment) {
        let blob;
        this.startLoading(clientAttachment.id);
        try {
            blob = await ClientAttachment.getBlob(clientAttachment.attachment as string);
        } catch (e) {
            let error;

            if (e instanceof Error) {
                error = e.message;
            } else {
                error = (e as { response: { data: { meta: { message: string } } } }).response.data.meta.message;
            }

            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: error,
            });
            return;
        }
        downloadFile(blob.data, clientAttachment.displayName);
        this.endLoading();
    }
}
