
import { Component, Prop, Vue } from 'vue-property-decorator';
import SidebarCard from '@/components/global/sidebar/SidebarCard.vue';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import LeadOriginRepository from '@/repositories/LeadOriginRepository';

@Component({
    name: 'ClientViewSidebarCreationSource',
    components: {
        SidebarCard,
    },
})
export default class ClientViewSidebarCreationSource extends Vue {
    @Prop({ required: true }) private value!: string;

    private editModeOn = false;
    private creationSource: string = '';
    private cachedUser: string = '';

    private get leadOrigin() {
        if (this.value && !this.creationSource) {
            this.creationSource = this.value;
        }

        return LeadOriginRepository.getById(this.value)?.name ?? '';
    }

    private get leadOrigins() {
        return LeadOriginRepository.getAll();
    }

    private onEdit() {
        this.cachedUser = this.creationSource;
        this.editModeOn = true;
    }

    private onCancel() {
        this.creationSource = this.cachedUser;
        this.editModeOn = false;
    }

    private handleChange(id: string) {
        this.creationSource = id;
    }

    private setLocalData(): void {
        this.editModeOn = false;

        this.$emit('input', this.creationSource);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.updateClientCachedData, this.setLocalData);
    }

    private mounted() {
        EventBus.$on(EventBusEvents.updateClientCachedData, this.setLocalData);
    }
}
