
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SidebarCard from '@/components/global/sidebar/SidebarCard.vue';
import ChatMessageList from '@/components/views/ChatView/ChatMessageList.vue';
import ClientTypeRepository from '@/repositories/ClientTypeRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';

interface ClientTag {
    name: string;
    color: string;
}

@Component({
    name: 'ClientViewSidebarTags',
    components: {
        SidebarCard,
        ChatMessageList,
    },
})
export default class ClientViewSidebarTags extends Vue {
    @Prop({ required: true }) private value!: string[];
    @Prop({ required: true }) private isCompany!: boolean;

    private editModeOn = false;
    private typeNames: ClientTag[] = [];

    private tags: string[] = [];
    private cachedTags: string[] = [];

    private get displayCustomerType() {
        return this.$t(this.isCompany ? 'Company' : 'Natural person');
    }

    private get clientTypes() {
        return ClientTypeRepository.getAll();
    }

    /* tslint:disable:no-bitwise */
    private hexToRgb(hex: string): string {
        const bigint = parseInt(hex.substring(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return r + ',' + g + ',' + b;
    }
    /* tslint:enable:no-bitwise */

    private getTagStyle(color: string) {
        return {
            color: color,
            border: '1px solid ' + color,
            'background-color': 'rgba(' + this.hexToRgb(color) + ', 0.125)',
        };
    }

    private onEdit() {
        this.editModeOn = true;
        this.cachedTags = [...this.tags];
    }

    private onCancel() {
        this.tags = [...this.cachedTags];
        this.editModeOn = false;
    }

    private setLocalData() {
        this.editModeOn = false;
        this.filterTypeNames(this.tags);

        this.$emit('input', this.tags);
    }

    private filterTypeNames(data: string[]): void {
        this.typeNames = [];

        data.map((item) => {
            const type = this.clientTypes.find((typeItem: any) => typeItem.id === item);

            if (type) {
                if (type.name === this.displayCustomerType) {
                    const replica = type.id;

                    if (replica) {
                        this.tags = this.tags.filter((tag) => tag !== replica);
                    }
                } else {
                    this.typeNames.push({
                        name: type.name,
                        color: type.color,
                    });
                }
            }
        });
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.updateClientCachedData, this.setLocalData);
    }

    private mounted() {
        EventBus.$on(EventBusEvents.updateClientCachedData, this.setLocalData);
    }

    @Watch('value', { immediate: true })
    private updateTypes() {
        this.tags = [...this.value];
        this.filterTypeNames(this.value);
    }
}
